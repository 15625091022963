import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/home.vue'
export const Layout = () => import("@/layout/index.vue");

const routeList: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "home",
        component: () => import("@/views/home.vue"),
        name: "home",
        meta: { title: "home", requireAuth: true, menuIndex: '1' },
      },
    ],
  },
  {
    path: "/about",
    component: Layout,
    children: [
      {
        path: "/about",
        component: () => import("@/views/about.vue"),
        name: "about",
        meta: { title: "about", requireAuth: true, menuIndex: '3' },
      },
    ],
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('@/views/login.vue'),
  //   meta: {
  //     title: 'login'
  //   }
  // },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: () => import('@/views/register.vue'),
  //   meta: {
  //     title: 'register'
  //   }
  // }
]


// 静态路由
export const mainRoutes: RouteRecordRaw[] = [
  {
    path: "/business",
    component: Layout,
    redirect: "/business",
    children: [
      {
        path: "/business",
        component: () => import("@/views/business/list.vue"),
        name: "business",
        meta: { title: "business", requireAuth: false },
      },
      {
        path: "/business/:cate",
        component: () => import("@/views/business/detail.vue"),
        name: "business-detail",
        meta: { title: "business-detail", requireAuth: false },
      },
    ],
  },
  {
    path: "/product",
    component: Layout,
    redirect: "/product",
    children: [
      {
        path: "/product",
        component: () => import("@/views/product/list.vue"),
        name: "product",
        meta: { title: "product", requireAuth: false },
      },
      {
        path: "/product/:cate",
        component: () => import("@/views/product/detail.vue"),
        name: "product-detail",
        meta: { title: "product-detail", requireAuth: false },
      },
    ],
  },
  {
    path: "/project",
    component: Layout,
    redirect: "/project",
    children: [
      {
        path: "/project",
        component: () => import("@/views/project/list.vue"),
        name: "project",
        meta: { title: "project", requireAuth: false },
      },
      {
        path: "/project/:cate",
        component: () => import("@/views/project/detail.vue"),
        name: "project-detail",
        meta: { title: "project-detail", requireAuth: false },
      },
    ],
  },
];

let routes = [...routeList, ...mainRoutes]
const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 你可以选择滚动到不同的位置
    // 返回的对象将告诉 Vue Router 滚动到哪个位置
    if (savedPosition) {
      return savedPosition; // 返回到上一个滚动位置
    } else {
      return { top: 0 }; // 滚动到页面顶部
    }
  },
})

/**
 * 重置路由
 */
export function resetRouter() {
  router.replace({ path: "/login" });
}

export default router
