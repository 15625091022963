import axios from 'axios'
import Nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import { ElMessage } from 'element-plus'

const req = axios.create({
  baseURL: 'https://aiapi.thvai.com/console/api',
  timeout: 150 * 1000, // 请求超时时间设置为30秒
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
})

const NETWORK_ERROR = '网络错误，请稍后重试'

/**
 * 请求拦截器
 */
req.interceptors.request.use((req) => {
  // console.log('请求拦截器 =>', req)
  Nprogress.start()
  return req;
}, (error) => {
  Nprogress.done()
  return Promise.reject(error);
});

/**
 * 响应拦截器
 */
req.interceptors.response.use(function (res) {
  // console.log('响应拦截器 =>', res)
  Nprogress.done()
  if (res.status == 200 || res.status == 201 || res.status == 204) {
    return res.data
  } else {
    ElMessage.error((NETWORK_ERROR))
    return Promise.reject(NETWORK_ERROR)
  }
});

export default req